import React from "react";
// import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const ContactPage: React.FC = () => (
    <Layout>
        <SEO title="Contact Us" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Contact Us</h1>

                <p>
                    Contact us at{" "}
                    <a href="mailto:autismsuccessintech@gmail.com">
                        autismsuccessintech@gmail.com
                    </a>
                    .
                </p>
            </div>
        </section>
    </Layout>
);

export default ContactPage;
